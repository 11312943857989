const accessibility = function () {
    $(window).on("keyup", function(e) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 9) {
            $(e.target).addClass('tab-focus');
        }
        if ($(e.target).is('input[type=radio]') || $(e.target).is('input[type=checkbox]')) {
            if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
                $(e.target).addClass('tab-focus');
            }
        }
        if (keyCode === 9 && $(e.target).is('input[type=file]')) {
            $(e.target).parents('.upload-input').find('.js-upload-button').addClass('tab-focus');
        }
    });

    $(window).on("keydown", function(e) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 9) {
            $(e.target).removeClass('tab-focus');
        }
        if ($(e.target).is('input[type=radio]') || $(e.target).is('input[type=checkbox]')) {
            if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
                $(e.target).removeClass('tab-focus');
            }
        }
        if (keyCode === 9 && $(e.target).is('input[type=file]')) {
            $(e.target).parents('.upload-input').find('.js-upload-button').removeClass('tab-focus');
        }
    });

    $(window).on("keypress", function(e) {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13
            && $(e.target).parents('form').length
            && (
                $(e.target).is('input[type=radio]') 
                || $(e.target).is('input[type=checkbox]') 
                || $(e.target).is('input[type=text]')
                )
            ) {
            e.preventDefault();
        }
    });

    $('body').on('click', function(e) {
        if (!$(e.target).is('input[type=radio]') && !$(e.target).is('input[type=checkbox]')) {
            $('.tab-focus').removeClass('tab-focus');
        }
    });

    // JavaScript snippet to find links with identical href but different text
    const links = [...document.querySelectorAll('a')];
    const grouped = links.reduce((acc, link) => {
        const href = link.getAttribute('href');
        if (href) {
            acc[href] = acc[href] || {};
            acc[href].texts = acc[href].texts || [];
            acc[href].texts.push(link.textContent.trim());
            acc[href].elements = acc[href].elements || [];
            acc[href].elements.push(link);
        }
        return acc;
    }, {});

    Object.entries(grouped).forEach(([href, group]) => {
        if (new Set(group.texts).size > 1) {
            const existingAriaLabel =  group.elements.find(el => el.getAttribute('aria-label')?.length > 0)?.getAttribute('aria-label');
            const text = group.texts.find(el => el.length > 0).replace(/\s+/g, ' ').trim();
            for (let i = 0; i < group.elements.length; i++) {
                const link = group.elements[i];
                link.setAttribute('aria-label', existingAriaLabel || text);
            }
        }
    });
};

export default accessibility;